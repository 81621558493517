<template>
  <a-spin :spinning="loading">
    <div class="body" style="">
      <div>

      </div>
      <div v-if="topMsg" class="header-msg">
        <iconpark-icon name="huangsejinggao"></iconpark-icon>
        {{ topMsg }}
      </div>
      <div class="container" ref="container" style="flex: 1">
        <div v-if="[enums.RETURNVISITTYPE.VIEW.v,enums.RETURNVISITTYPE.MANUAL_SURVEY.v].includes(testType)||visit_time"
             class="look" :style="{height:`${lookHeight}px`}">
        </div>
        <div class="header">
          <img v-if="!objFill.background_img" class="header_img" src="../../assets/image/qanAbg.jpg">
          <img v-else class="header_img" :src="objFill.background_img">
          <h1 class="title">{{ objFill.title }}</h1>
          <p class="welcome-content">{{ objFill.welcome_content }}</p>
        </div>
        <div class="research-body">
          <a-form-model class="form_container" :model="form" ref="form">
            <a-form-model-item
                v-for="(topic,index) in topicList"
                :key="topic.id"
                :ref="`formItem_${topic.id}`"
                :colon="false"
                :prop="topic.id.toString()"
            >
              <div>
                <div slot="label">
                  <span v-if="topic.must_fill===enums.BOOL.YES.v" style="color: #ff0000">*</span>
                  <span style="font-weight: 800">
                {{ `${index + 1}.${topic.name}` }}
                </span>
                  <a-tag class="tag-name">
                    {{ enums.getName(enums.RESEARCH_TYPE, topic.type) }}<span
                      v-if="![enums.RESEARCH_TYPE.NPS.v,enums.RESEARCH_TYPE.CSAT.v].includes(topic.type)">题</span>
                  </a-tag>
                  <p class="topic-profile">{{ topic.profile }}
                  </p>
                </div>
                <ChoiceEdit @pushOn="(d)=>setData(topic,d)" v-if="topic.type===enums.RESEARCH_TYPE.SELECTOR.v"
                            :option="topic"/>
                <MultipleEdit @pushOn="(d)=>setData(topic,d)" v-if="topic.type===enums.RESEARCH_TYPE.MULTI_SELECTOR.v"
                              :option="topic"/>
                <AnswerEdit :option="topic" @pushOn="(d)=>setData(topic,d)"
                            v-if="topic.type===enums.RESEARCH_TYPE.Q_AND_A.v"/>
                <EditRating @pushOn="(d)=>setData(topic,d)" v-if="topic.type===enums.RESEARCH_TYPE.SCORE.v"
                            :option="topic"/>
                <Cast :option="topic" @pushOn="(d)=>setData(topic,d,'cast')"
                      v-if="topic.type===enums.RESEARCH_TYPE.CSAT.v"/>
                <Nps :option="topic" @pushOn="(d)=>setData(topic,d)" v-if="topic.type===enums.RESEARCH_TYPE.NPS.v"/>
              </div>
            </a-form-model-item>
          </a-form-model>
          <div v-if="!testType" style="display: flex;width: 100%;justify-content: center;">
            <a-button v-if="!visit_time" type="primary" class="button-save" @click="save">提交</a-button>
          </div>
        </div>

      </div>
      <div class="remake" style=""
           v-if="[enums.RETURNVISITTYPE.EVALUATION.v,enums.RETURNVISITTYPE.MANUAL_SURVEY.v].includes(testType)">
        <a-form-model layout="vertical">
          <a-form-model-item  >
            <div slot="label"><span style="color: #ff0000">*</span> 回访备注</div>
            <a-textarea v-model="info.remark" :auto-size="{ minRows: 3, maxRows: 4 }"/>
          </a-form-model-item>
        </a-form-model>
        <div>
          <p style="font-weight: bold">联系信息</p>
          <p>{{ info.linkman_name || "无" }} {{ info.linkman_mobile || "无" }}</p>
          <p>{{ info.address || "无" }}</p>
        </div>
        <a-button v-if="!manual_visit_time" class="a-btn" @click="save" type="primary">提交</a-button>
      </div>
    </div>
  </a-spin>
</template>

<script>
import request from "@/utils/request";
import enums from "@/utils/enums";
import ChoiceEdit from "@/views/research/components/ChoiceEdit.vue";
import MultipleEdit from "@/views/research/components/MultipleEdit.vue";
import AnswerEdit from "@/views/research/components/AnswerEdit.vue";
import EditRating from "@/views/research/components/EditRating.vue";
import Cast from "@/views/research/components/Cast.vue";
import Nps from "@/views/research/components/Nps.vue";
import {getElementPosition, isArray} from "@/utils/utils";
import {Toast} from 'vant'

export default {
  name: "Research",
  components: {Nps, Cast, EditRating, AnswerEdit, MultipleEdit, ChoiceEdit},
  data() {
    return {
      enums,
      cast: null,
      rules: {},
      form: {},
      loading: true,
      id: null,
      objFill: {},
      info: {
        remark: ''
      },
      topicList: [],
      lookHeight: 0,
      token: null,
      isShowButton: true,
      research_template_id: null,
      enterprise_id: null,
      auto_visit_time: null,
      manual_visit_time: null,
      testType: null,
      topMsg: null,
      visit_time: null,
      autoMsg:"",
      manualMsg:""
    }
  },
  created() {
    if (this.$route.params.token) {
      this.token = this.$route.params.token
    }
    this.id = this.$route.params.id
    this.testType = +this.$route.params.type
    if (this.testType === enums.RETURNVISITTYPE.PREVIEW.v) {
      this.getPreviewData()
      this.isShowButton = true
    } else if (this.id) {
      this.getDetail(+this.id)
    window.addEventListener('message',e => {
      if (e.data.auto){
        console.log(e.data)
      }
    })
    }
  },
  methods: {
    comHeight() {
      const heightList = []
      if (document.querySelector(".header")) {
        heightList.push(document.querySelector(".header").offsetHeight)
      }
      if (document.querySelector(".research-body")) {
        heightList.push(document.querySelector(".research-body").offsetHeight)
      }
      if (document.querySelector(".remake") && this.manual_visit_time) {
        heightList.push(document.querySelector(".remake").offsetHeight)
      }
      let height = 0
      heightList.forEach(item => {
        height += item
      })
      this.lookHeight = height + 50
    },
    getPreviewData() {
      this.topMsg = '当前为预览界面，回答题目将不做统计'
      request({
        url: '/research_template/preview',
        data: {id: this.id}
      }).then(res => {
        this.loadData(res)
      }).finally(() => {
        this.loading = false
        this.comHeight()
      })
    },
    loadData(res) {
      const data = res?.data?.data?.research_template
      this.topicList = data.research_question.sort((a, b) => a.order_number - b.order_number)
      this.research_template_id = res.data.data.research_template_id
      this.enterprise_id = res.data.data.enterprise_id
      this.info.address = res?.data?.data?.full_address
      this.info.linkman_mobile = res?.data?.data?.linkman_mobile
      this.info.linkman_name = res?.data?.data?.linkman_name
      this.info.remark = res?.data.data.manual_visit_content
      this.auto_visit_time = res.data.data.auto_visit_time
      this.manual_visit_time = res.data.data.manual_visit_time
      this.objFill = {
        title: data.title,
        welcome_content: data.welcome_content,
        background_img: data.background_img
      }
      this.visit_time = [this.auto_visit_time, this.manual_visit_time].filter(d => d !== null)[0]
    },
    setData(topic, data, cast) {
      this.form[topic.id.toString()] = data
      if (cast === "cast") {
        this.cast = data
      }
    },
    getDetail(id) {
      request({
        url: '/work_order/auto_visit_detail',
        data: {id}
      }).then(res => {
        this.loadData(res)
      }).catch(() => this.$router.push({name: '404'})).finally(() => {
        this.comHeight()
        this.loading = false
        if (this.visit_time) {
          this.topMsg = `当前问卷于 ${this.visit_time} 进行了提交`
          this.isShowButton = false
        }
      })
    },
    save() {
      if (this.testType === enums.RETURNVISITTYPE.MANUAL_SURVEY.v || this.visit_time) {
        if (!this.info.remark) {
          Toast({
            position: 'top',
            message: `请填写回访备注`
          })
          return
        }
        request({
          url: "/work_order/re_visit",
          data: {
            work_order_id: this.id,
            enterprise_id: this.enterprise_id,
            template_id: this.research_template_id,
            token: this.token,
            remark: this.info.remark
          }
        }).then(res => {
          if (res.data.success) {
            window.parent.postMessage("over", "*")
            Toast({
              position: 'top',
              message: `提交成功`
            })
          } else {
            window.parent.postMessage("error", "*")
            Toast({
              position: 'top',
              message: res.data.msg
            })
          }
        }).catch(() => {
          window.parent.postMessage("error", "*")
          Toast({
            position: 'top',
            message: `提交失败`
          })
        }).finally(() => {
          setTimeout(() => {
            // eslint-disable-next-line no-undef
            uni.navigateBack()
          }, 1200)
        })
        return
      }
      let isReturns = false
      this.topicList.forEach((topic, topicIndex) => {
        if (!isReturns) {
          if (topic.must_fill === enums.BOOL.YES.v) {
            if ([undefined, null, "", []].includes(this.form[topic.id]) || this.form[topic.id]?.length === 0) {
              Toast({
                position: 'top',
                message: `第${topicIndex + 1 + '题' + topic.name}必须填写。`
              })
              document.querySelector('.container').scrollTo({
                top: getElementPosition(this.$refs[`formItem_${topic.id}`][0].$el).y - 30,
                behavior: 'smooth'
              })
              isReturns = true
            }
          }
        }
      })
      if (!isReturns) {
        const resultList = this.topicList.map(d => {
          const result = {
            question_id: d.id,
            the_key: isArray(
                this.form[d.id.toString()])
                ? this.form[d.id.toString()].join(',')
                : this.form[d.id.toString()],
            the_value: isArray(
                this.form[d.id.toString()])
                ? (() => {
                  const result = {}
                  d.research_question_option
                      .filter(value => this.form[d.id.toString()]
                          .includes(value.the_key))
                      .map(d => {
                        result[d.the_key] = d.the_value
                      })
                  return result
                })()
                : (() => {
                  const result = {}
                  result[this.form[d.id.toString()]] = d.research_question_option
                      .find(item => item.the_key === this.form[d.id.toString()])
                      ?.the_value
                  Object.values(result).forEach((obj, index) => {
                    if (obj === undefined) {
                      result[Object.keys(result)[index]] = Object.keys(result)[index]
                    }
                  })
                  return result
                })()
          }
          return result
        }).map(d => ({...d, the_value: JSON.stringify(d.the_value)}))
        const postData = {
          work_order_id: this.id,
          enterprise_id: this.enterprise_id,
          template_id: this.research_template_id,
          research_answer_list: resultList.filter(d => d.the_key !== undefined && d.the_key.length !== 0)
        }
        if (this.cast) {
          postData['score'] = this.cast
        }
        if (!this.testType) {
          request({
            url: '/work_order/auto_visit',
            data: postData
          }).then(res => {
            if (res.data.success) {
              window.parent.postMessage("over", "*")
              Toast({
                position: 'top',
                message: `提交成功`
              })
              location.reload()
            } else {
              window.parent.postMessage("error", "*")
              Toast({
                position: 'top',
                message: res.data.msg
              })
            }
          }).catch(() => {
            window.parent.postMessage("error", "*")
            Toast({
              position: 'top',
              message: `提交失败`
            })
          }).finally(() => {
            setTimeout(() => {
              // eslint-disable-next-line no-undef
              uni.navigateBack()
            }, 1200)
          })
        }else {
          if (!this.info.remark) {
            Toast({
              position: 'top',
              message: `请填写回访备注`
            })
            return
          }
          request({
            url: "/work_order/re_visit",
            data: {
              work_order_id: this.id,
              enterprise_id: this.enterprise_id,
              template_id: this.research_template_id,
              token: this.token,
              remark: this.info.remark,
              research_answer_list: resultList.filter(d => d.the_key !== undefined && d.the_key.length !== 0)
            }
          }).then(res => {
            if (res.data.success) {
              window.parent.postMessage("over", "*")
              Toast({
                position: 'top',
                message: `提交成功`
              })
            } else {
              window.parent.postMessage("error", "*")
              Toast({
                position: 'top',
                message: res.data.msg
              })
            }
          }).catch(() => {
            window.parent.postMessage("error", "*")
            Toast({
              position: 'top',
              message: `提交失败`
            })
          }).finally(() => {
            setTimeout(() => {
              // eslint-disable-next-line no-undef
              uni.navigateBack()
            }, 1200)
          })
        }
      }
    }
  }
};
</script>

<style scoped lang="less">
.body{
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-x: hidden;
  background: #ffffff;
}
.container {
  background: #fff;
  position: relative;
  .header {
    padding: 10px;
    padding-bottom: 0;

    .header_img {
      width: 100%;
      height: 150px;
      object-fit: cover;
      border-radius: 5px;
    }

    .title {
      text-align: center;
      font-size: 17px;
      margin: 0;
      margin-top: 8px;
    }

    .welcome-content {
      font-size: 12px;
      font-weight: normal;
      line-height: 17px;
      color: #595959;
      padding: 0 10px;
      margin: 0;
    }
  }

  .research-body {
    padding: 0 18px;
  }

  .topic-profile {
    color: #9A9A9A;
    letter-spacing: 1px;
    font-size: 12px;
    line-height: 12px;
    margin: 0;
    padding: 0;
  }
}

/deep/ .ant-form-item-label {
  font-weight: bold;
}

/deep/ .ant-form-item {
  margin-bottom: 2px !important;
}

.tag-name {
  border: none;
  color: #A4A4A4;
}

.button-save {
  height: 45px;
  font-size: 18px;
  width: 50vw;
  margin-top: 20px;
  margin-bottom: 30px;
}

.header-msg {
  margin: 10px 10px 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FEFBE6;
  height: 30px;
  line-height: 30px;
  color: #646464;

  iconpark-icon {
    margin-right: 5px;
  }
}

.look {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 500;
}

.remake {
  padding: 0 20px;
  padding-top: 12px;
  height: 250px;
  background: #fff;

  p {
    margin-bottom: 4px;
  }

  p:last-child {
    padding-bottom: 50px;
  }

  .a-btn {
    height: 45px;
    font-size: 18px;
    width: 50vw;
    left: 50%;
    transform: translateX(-50%);
    margin: 10px 0;
  }
}
</style>
